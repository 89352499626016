function buildItemAddedModbox(id_produit, is_not_produit) {
    var form = document.getElementById("prod_info_" + id_produit);
    var closing_method =
        is_not_produit == "true"
            ? "window.location.reload()"
            : "closeMultiShad('modbox')";

    if (document.getElementById("nbr_visu_0")) {
        var itm_vis = $("img", "#nbr_visu_0")
            .attr("src")
            .replace("/mini/", "/large/");
    }

    var itm_name = $("#itm_name", form)
        .get(0)
        .textContent.trim();

    if ($("#itm_feat", form).length > 0) {
        var itm_feat = $("#itm_feat", form)
            .get(0)
            .textContent.trim();
    }

    var itm_price = $(".pricetag:first", form).html();

    if ($(".pricetag:first + .pricetag", form).length) {
        var itm_fullprice = $(".pricetag:first + .pricetag", form).html();
    }

    var itm_price = $(".price_tag:first", form).html();

    if ($(".price_tag:first + .price_tag", form).length) {
        var itm_fullprice = $(".price_tag:first + .price_tag", form).html();
    }

    if (form) {
        var itm_length = parseFloat(form.elements.namedItem("qteProd").value);
        var itm_color = form.elements.namedItem("couleurProd").value;
        var itm_size = form.elements.namedItem("tailleProd").value;

        if (form.getValue("produit_lot_nb", parseFloat) > 1 || itm_length > 1) {
            itm_length += " " + Translator.translate("items");
        } else {
            itm_length += " " + Translator.translate("item");
        }

        if (itm_length == 1) {
            itm_length += " " + Translator.translate("added_to_cart_sn");
        } else if (form.getValue("produit_lot_nb") == "1") {
            itm_length += " " + Translator.translate("added_to_cart_pl");
        }

        itm_color = $("input[name=itm_color]", form)
            .filter("[value=" + itm_color + "]")
            .next("label")
            .attr("title")
            .trim();
        itm_size = $("input[name=itm_size]", form)
            .filter("[value=" + itm_size + "]")
            .next("label")
            .attr("title")
            .trim();
    }

    $("#shade").after($("#modbox"));

    document.getElementById("modbox").innerHTML = Mustache.render(
        addToCartTmpl,
        {
            closing_method: closing_method,
            itm_vis: itm_vis,
            itm_name: itm_name,
            itm_feat: itm_feat,
            itm_price: itm_price,
            itm_fullprice: itm_fullprice,
            itm_length: itm_length,
            itm_color: itm_color,
            itm_size: itm_size,
        }
    );
    // Si c'est la premiere fois on crée l'image et ensuite on l'insere a la suite
    // C'est pour éviter que le serveur appel une image qui n'existe pas
    if ($("#modbox_visu").length > 0) {
        var span = $("#modbox_visu");
        // Creation de l'image
        var img = $("<img>", {
            src: span.data("src"),
            alt: span.data("alt"),
        });
        $("#modbox_visu").after(img);
        $("#modbox_visu").remove();
    }
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function () {

        topCartDesappear();
    }, 2000); 
}

function showsendfriendmodal(product_id, path_web, type) {
    var elname = type === "look_basket" ? "modbox" : "sendfriendbox";
    var actualposition = document.documentElement.scrollTop;

    if (type !== "look_basket") {
        var psnok = document.getElementById('product_sendfriend_nok');
        var psfok = document.getElementById('product_sendfriend_ok');
        if (psnok) {
            psnok.innerHTML = '';
            psnok.style.display = 'none';
        }
        if (psfok) {
            psfok.innerHTML = '';
            psfok.style.display = 'none';
        }

        prodGetProductDisplay(product_id, path_web, type);

        openMultiShad("sendfriendbox");
    } else {
        show_lightbox(elname);
        reloadLookparts();
        $(".loader", "#bloc_add_basket").hide();
    }
}

function closesendfriendmodal() {
    closeMultiShad("sendfriendbox");

    document.getElementById("sendfriend_alert_mail").style.display = "none";
    document.getElementById("sendfriend_form_to").style.display = "block";
    document.getElementById("sendfriend_form_froms").style.display = "block";
    document.getElementById("sendfriend_form_buttons").style.display = "block";
    document.body.style.overflow = "";
    document.getElementsByTagName("html")[0].style.overflow = "";

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "visible";
        }
    }
}

/** Check if products already in cart > 2 = init scrollbar */
if ($('#cart_top .cart').attr('data-cart') > 3) {
    setTimeout(function() {
        OverlayScrollbars(document.querySelector('#cart_list'), {});
    }, 100);
}

if (parseInt($('#cart_top .cart').attr('data-cart')) > 2 && $(window).width() === 1024) {
    setTimeout(function() {
        OverlayScrollbars(document.querySelector('#cart_list'), {});
    }, 100);
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {

        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {
    var id = '';

    prix_id = 'prixU';

    if ((from == 'basket' || is_not_produit == true || is_achat_express) && typeof(itm_rr_id) != 'undefined') {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        // Datalayer v2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }
            pushIntoDatalayer(dl_objet);
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top .sub_menu_content").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 3) {
            setTimeout(function() {
                OverlayScrollbars(document.querySelector('#cart_list'), {});
            }, 100);
        }

        $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    let getNewTemoin = $("#cart_top .sub_menu a.cart .rolloverTxt span:first-child").text();
    let getNewTemoinTrim = $.trim(getNewTemoin);
    
    if (getNewTemoinTrim.length < 4) {
        $("#cart_top .item_title.nb_basket").text(getNewTemoinTrim);
        
    } else {
        $("#cart_top .item_title.nb_basket").text(' ');
    }

    /* Get the number of produts in the hover panier */
    var getPanierHoverNoProduit = $("#show_top_cart #cart_list .product-link").length;
    if (getPanierHoverNoProduit > 2) {
        $("#show_top_cart #cart_list").addClass("more_than_two_products");
    }

    if (getPanierHoverNoProduit > 3) {
        $("#show_top_cart #cart_list").addClass("more_than_three_products");
    }

    var count_bask = $('#cart_top .cart').attr('data-cart');
    if (count_bask > 3) {
        OverlayScrollbars(document.querySelector( "#show_top_cart #cart_list" ), {});
    }

    return false;
}

function displayZoom(
    picture,
    product_ref,
    path_web,
    picture_medium,
    picture_mini,
    photo_path,
    photo_list
) {
    var so = new SWFObject(
        path_web + "zoom.swf",
        "zoom",
        "405",
        "610",
        "8",
        "#FFFFFF"
    );
    so.addParam("wmode", "transparent");
    so.addParam("allowScriptAccess", "always");
    so.addVariable("produit_id", product_ref);
    so.addVariable("url", path_web);

    so.addVariable("photo_path_medium", picture_medium);
    so.addVariable("photo_path_mini", picture_mini);

    so.addVariable("photo_path", photo_path);

    so.addVariable("photo_list", photo_list);
    so.addVariable("img_defaut", picture);
    so.write("flashVisuel");
}

function zoom(
    picture,
    product_ref,
    path_web,
    picture_medium,
    picture_mini,
    photo_path,
    photo_list
) {
    displayZoom(
        picture,
        product_ref,
        path_web,
        picture_medium,
        picture_mini,
        photo_path,
        photo_list
    );
}

function updateZoomedImg(src) {

    $(".main_vis img", this).attr("src", src);
}

/*
function zoomImg() {

    //var container = $("#visual_scroller");
    var thumbnail = $(".swiper-slide", container).clone();
    var main_visu = $(this)
        .clone()
        .removeAttr("id");
    var zoom_wrap = $("#zoom_box_wrapper");

    thumbnail.each(function() {
        var src = $("img", this)
            .attr("src")
            .replace("/medium/", "/large/");
        src = $("img", this)
            .attr("src")
            .replace("/mini/", "/large/");

        $(this).removeAttr("id");
        $("img", this).removeAttr("onclick");
        $(this).addClass("inLight");

        this.addEventListener("mouseover", updateZoomedImg.bind(zoom_wrap, src));
    });

    $(".main_vis", zoom_wrap).html(main_visu);
    $(".more_vis", zoom_wrap).html(thumbnail);

    openMultiShad("zoomBox");
}

*/


function zoomImg(slide) {

    $('#zoom_box_wrapper').css('opacity', 0);

    openMultiShad('zoomBox');

    window.setTimeout(function () {

        var productLightboxVisu = new Swiper('.productLightboxVisu', {
            direction: "vertical",
            slidesPerView: 1,
            updateOnWindowResize: true,
            allowTouchMove: false,
            effect: 'fade',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                style: "progressbar",
            },
        });

        productLightboxVisu.slideTo(slide);

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 250);
}


function updateSrc(path_web, dest) {
    var tmp_string_large = document.getElementById("img_large").src;
    tmp_string = tmp_string_large.replace(/medium/, "large");

    document
        .getElementById("img_large")
        .setAttribute(
            "onclick",
            "zoomImg('" + tmp_string + "','" + path_web + "')"
        );
    document.getElementById("loupe").href =
        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

    if (dest == "vue") {
        var i = 0;
        var trouve = false;
        while (i < tab_js.length && !trouve) {
            trouve =
                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
            i++;
        }

        cpt_img = i - 1;
    }
}

function productSendFriendForm(path_web, request_uri) {
    document.getElementById("product_sendfriend_ok").style.display = "block";
    document.getElementById("product_sendfriend_nok").style.display = "block";

    //var content = "<p style=\"margin: 0px;padding: 0px;\">L'e-mail n'a pas pu �tre envoy� � votre ami.</p>";

    if (
        document.getElementById("sendFriendToMails") &&
        document.getElementById("sendFriendToMessage") &&
        document.getElementById("sendFriendYourName") &&
        document.getElementById("sendFriendYourMail") &&
        document.getElementById("sendFriendCopy")
    ) {
        document.getElementById("sendFriendToMails").style.border = "";
        document.getElementById("sendFriendToMessage").style.border = "";
        document.getElementById("sendFriendYourName").style.border = "";
        document.getElementById("sendFriendYourMail").style.border = "";

        var token = document.getElementById("token_security_sendfriend").value;
        var captcha = document.getElementById("g-recaptcha-response").value;

        var params_add = '';
        if (document.getElementById("produit_id")) {
            params_add +=  '&product_id='+document.getElementById("produit_id").value;
        }

        $.ajax({
            url: path_web + "ajax_send_friend_form.php",
            type: "POST",
            data:
                "g-recaptcha-response=" +
                captcha +
                "&token_security=" +
                token +
                "&url=" +
                request_uri +
                "&to_mails=" +
                document.getElementById("sendFriendToMails").value +
                "&to_message=" +
                document
                    .getElementById("sendFriendToMessage")
                    .value.replace(/\n/g, "<br>") +
                "&your_name=" +
                document.getElementById("sendFriendYourName").value +
                "&your_mail=" +
                document.getElementById("sendFriendYourMail").value +
                "&copy=" +
                document.getElementById("sendFriendCopy").checked + params_add,
        }).done(function(response) {
            var content_id = "product_sendfriend_nok",
                content = "";

            if (response.split(",")[0] == "ok") {
                var s_adresse = response.substr(3);
                var sing =
                    response.split(",").length > 2
                        ? "sendfriend_ok_plur"
                        : "sendfriend_ok_sing";

                content =
                    '<div style="font-size:14px;font-weight:bold;font-family:Arial; color:#222;text-align:center;margin-bottom:10px;margin-top:15px;font-family: arial;">' +
                    ajax_file(path_web + "ajax_sprintf.php?arg1=" + sing) +
                    "</div>";
                content +=
                    '<p style="margin-top: 0px;margin-bottom: 30px;padding:0;text-align:center;">' +
                    s_adresse +
                    "</p>";

                content_id = "sendfriend_alert_mail";

                document.getElementById("product_sendfriend_nok").innerHTML =
                    "";
                document.getElementById("sendfriend_form_to").style.display =
                    "none";
                document.getElementById("sendfriend_form_froms").style.display =
                    "none";
                document.getElementById(
                    "sendfriend_form_buttons"
                ).style.display =
                    "none";
                document.forms["sendfriendform"].reset();

                $(".form_submit.loading", "#sendfriendform").removeClass(
                    "loading"
                );

                // setTimeout(function () {

                // 	closeMultiShad('sendfriendbox');
                // }, 600);
            } else {
                $("#sendFriendToMails").removeClass("inputErr");
                $("#sendFriendToMessage").removeClass("inputErr");
                $("#sendFriendYourName").removeClass("inputErr");
                $("#sendFriendYourMail").removeClass("inputErr");

                if ($("#sendFriendToMessage").val() == "")
                    $("#sendFriendToMessage").addClass("inputErr");
                if ($("#sendFriendYourName").val() == "")
                    $("#sendFriendYourName").addClass("inputErr");
                if ($("#sendFriendYourMail").val() == "")
                    $("#sendFriendYourMail").addClass("inputErr");

                content =
                    '<img src="' +
                    path_relative_root +
                    'img/picto_panneau.png" style="margin-right:10px;float:left;" /><p style="margin: 0px;padding: 0px; line-height: 24px;">' +
                    ajax_file(path_web + "ajax_sprintf.php?arg1=error_fields") +
                    "</p>";

                if (response == "1") {
                    content =
                        '<img src="' +
                        path_relative_root +
                        'img/picto_panneau.png" style="margin-right:10px;float:left;" /><p style="margin: 0px;padding: 0px; line-height: 24px;">' +
                        ajax_file(
                            path_web +
                                "ajax_sprintf.php?arg1=sendfriend_error_no_mail_friends"
                        ) +
                        "</p>";
                    $("#sendFriendToMails").addClass("inputErr");
                    $("td.labelForm").addClass("labelErr");
                } else if (response == "2") {
                    content =
                        '<img src="' +
                        path_relative_root +
                        'img/picto_panneau.png" style="margin-right:10px;float:left;" /><p style="margin: 0px;padding: 0px; line-height: 24px;">' +
                        ajax_file(
                            path_web +
                                "ajax_sprintf.php?arg1=sendfriend_error_mail_friends_invalid"
                        ) +
                        "</p>";
                    $("#sendFriendToMails").addClass("inputErr");
                    $("td.labelForm").addClass("labelErr");
                } else if (response == "6") {
                    content =
                        '<img src="' +
                        path_relative_root +
                        'img/picto_panneau.png" style="margin-right:10px;float:left;" /><p style="margin: 0px;padding: 0px; line-height: 24px;">' +
                        ajax_file(
                            path_web +
                                "ajax_sprintf.php?arg1=sendfriend_error_no_my_mail"
                        ) +
                        "</p>";
                    $("#sendFriendYourMail").addClass("inputErr");
                    $("td.labelForm").addClass("labelErr");
                } else if (response == "7") {
                    content =
                        '<img src="' +
                        path_relative_root +
                        'img/picto_panneau.png" style="margin-right:10px;float:left;" /><p style="margin: 0px;padding: 0px; line-height: 24px;">' +
                        ajax_file(
                            path_web +
                                "ajax_sprintf.php?arg1=sendfriend_error_my_mail_invalid"
                        ) +
                        "</p>";
                    $("#sendFriendToMails").addClass("inputErr");
                    $("td.labelForm").addClass("labelErr");
                } else if (response == "token_error") {
                    content =
                        '<p class="txt_err_sendfriend">' +
                        ajax_file(
                            path_web +
                                "ajax_sprintf.php?arg1=error_token_security"
                        ) +
                        "</p>";
                } else if (
                    response == "3" ||
                    response == "4" ||
                    response == "5"
                ) {
                    $("#sendFriendToMessage").addClass("inputErr");
                    $("#sendFriendYourName").addClass("inputErr");
                } else {
                    var s_adresse = response.substr(3);
                    var sing =
                        response.split(",").length > 2
                            ? "sendfriend_no_ok_plur"
                            : "sendfriend_no_ok_sing";

                    content =
                        '<div class="msg_poperror">' +
                        Translator.translate(sing) +
                        "</div>";
                    content_id = "sendfriend_alert_mail";
                    content +=
                        '<div class="form_submit">' +
                        '<a class="button primary" href="#" onclick="closesendfriendmodalfromconfirm()">' +
                        "<span>" +
                        Translator.translate("ok") +
                        "</span>" +
                        "</a>" +
                        "<div>";

                    document.getElementById(
                        "product_sendfriend_nok"
                    ).innerHTML =
                        "";
                    document.getElementById(
                        "sendfriend_form_to"
                    ).style.display =
                        "none";
                    document.getElementById(
                        "sendfriend_form_froms"
                    ).style.display =
                        "none";
                    document.getElementById(
                        "sendfriend_form_buttons"
                    ).style.display =
                        "none";

                    document.getElementById("sendFriendToMails").value = "";
                    document.getElementById("sendFriendToMessage").value = "";
                    document.getElementById("sendFriendYourName").value = "";
                    document.getElementById("sendFriendYourMail").value = "";

                    $(".form_submit.loading", "#sendfriendform").removeClass(
                        "loading"
                    );

                    // setTimeout(function () {

                    // 	closeMultiShad('sendfriendbox');
                    // }, 600);
                }
            }

            document.getElementById(content_id).innerHTML = content;
            document.getElementById(content_id).style.display = "block";
            $("#sendfriend_form_buttons").removeClass("loading");
        });
    }

    return false;
}

function prodGetProductDisplay(id, path_web, type) {
    if (typeof type == "undefined") {
        var response = ajax_file(
            path_web + "ajax_get_infos_send_friend.php?product_id=" + id
        );
        document.getElementById("sendfriend_prodinfos").innerHTML = response;
    } else {
        if (type == "look") {
            $("#titre_popup").html(
                ajax_file(
                    path_web + "ajax_sprintf.php?arg1=sendfriend_title_look "
                )
            );
            $("#friend_form").show();
            $("#btn_popup_panier").hide();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else if (type == "look_basket") {
            $("#titre_popup").html("Vous venez d'ajouter le look suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();

            $("#bloc_btn_look").show();
            $("#bloc_btn_loader_look").hide();
        } else {
            $("#titre_popup").html("Vous venez d'ajouter le produit suivant");
            $("#friend_form").hide();
            $("#btn_popup_panier").show();
        }
    }

    if (
        navigator.userAgent.indexOf("MSIE") != -1 &&
        navigator.userAgent.indexOf("6.") != -1
    ) {
        var svn = document.getElementsByTagName("SELECT");
        for (a = 0; a < svn.length; a++) {
            svn[a].style.visibility = "hidden";
        }
    }
}

function show_lightbox(lightbox_contact) {
    $("#shad")
        .fadeTo("slow", 0.8)
        .queue(function() {
            $("#" + lightbox_contact).slideDown("slow", function() {
                $("#visu_popup_ajout_panier", this).recalImg();
            });

            $(this).dequeue();
        })
        .click(function() {
            close_lightbox(lightbox_contact);
        });

    $("html, body").animate({scrollTop: 0}, $(document).scrollTop() * 0.8);
}

function close_lightbox(lightbox_contact) {
    $("#" + lightbox_contact)
        .slideUp("slow")
        .queue(function() {
            $("[id^=shad]").each(function() {
                if ($(this).is(":visible")) {
                    $(this).fadeTo("slow", 0, function() {
                        $(this).css("display", "none");
                    });
                }
            });

            $(this).dequeue();
        });
}


// /**
//  * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
//  */
function lightboxAlertStock() {

    var taille_id = $("#tailleProd").val(),
        couleur_id = $("#couleurProd").val();

    if (taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if (couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('#bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('#bloc_add_alert_erreur').is(':visible');
    var alertStock = $('#alerteStock').val();

    if (isFormVisible || isMessageVisible || isErrorVisible || alertStock !== 'true') {
        if (isFormVisible) {
            $('#bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
            $('#btn_add_alert').show();
        }

        if (isErrorVisible) {
            $('#bloc_add_alert .bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $("#bloc_add_alert #btn_add_alert").css("display", "none");
        $('#bloc_add_alert .bloc_add_alert_form').slideDown('fast');
    }
}

// /*
//  * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
//  */
function sendMailAlertStock (type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id= form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $( '#mail_alerte_stock' + elem ).addClass( 'inputErr' );

    } else {
        $( '#mail_alerte_stock' + elem ).removeClass( 'inputErr' );
        
        $.ajax({
            type : 'post',
            url : path_relative_root + create_link('ajax_mail_alert_stock'),
            data : {
                email : $email,
                produit_id : $produit_id,
                taille_id : $taille_id,
                couleur_id : $couleur_id,
                type_alert : $type_alert
            },
            success : function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail : function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

// Datalayer v2
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// Datalayer v2
function dataLayer_associated_product () {
    if (window.$('#carousel_wrapper')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item) {
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']}, // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}
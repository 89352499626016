$(function() {
    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (header.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    header.classList.remove("sticky");
                    shade.classList.remove("sticky");
                }
            } else {
                if (scrollTop !== 0) {
                    header.classList.add("sticky");
                    shade.classList.add("sticky");
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus") {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    /** pushProductHomeWrapper MODULE SWIPER */
    var pushProductHomeWrapper = new Swiper('.pushProductHomeWrapper', {
        slidesPerView: 1,
        spaceBetween: 50,
        speed: 2000,

        navigation: {
            nextEl: '.swiper-pushProduct-button-prev',
            prevEl: '.swiper-pushProduct-button-next',
        },

        autoplay: {
            delay: 5000,
        },
        // lazy: true
    });
});
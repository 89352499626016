// Simule le défilement infini sur desktop pour les rayons et la recherche après un premier clic sur le bouton "Voir plus".
// La fonction listenForButtonClick() vérifie si les éléments requis du DOM sont présents. Si c'est le cas, alors on ajoute ou retire un événement "scrollEventlistener".
// Cet événement est également activé et désactivé au début et à la fin de la requête ajax pour éviter un nombre trop conséquent de clics pendant le chargement des produits. Voir => generateNewBlocProd dans functions.js.
function listenForButtonClick() {
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');
    if (button) {
        button.addEventListener('click', () => {
            const maxItems = document.getElementById('totalElems').value;
            const productLoaded = $('#list_item .item:not(".push")').length;

            if(productLoaded == maxItems) {
                // Stop infinite scroll only when all products loaded
                seeAllBtnClick();

                // Store the state in session storage
                sessionStorage.setItem('infiniteScrollState', 'activated');
            }
        });
    }
}

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, elle retourne true et le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Fonction qui active ou désactive le scroll listener en fonction de si le premier clic est effectué et de l'avancée de la requête ajax. Voir => generateNewBlocProd. 
function seeAllBtnClick(action=null) {

    if (action=="add") {
        window.addEventListener('scroll', handleScroll);
        window.hasScrollListener = true;
    } else if (action=="remove") {
        window.removeEventListener('scroll', handleScroll);
        window.hasScrollListener = false;
    } else {
        if (window.hasScrollListener) {
            window.removeEventListener('scroll', handleScroll);
            window.hasScrollListener = false;
        } else {
            window.addEventListener('scroll', handleScroll);
            window.hasScrollListener = true;
        }
    }
  }

// Deuxième fonction qui écoute la position du scroll par rapport à la hauteur totale de la page. La condition demande que la fonction isInViewport renvoie true.
function handleScroll() {
    const maxItems = document.getElementById('totalElems').value;
    const productLoaded = $('#list_item .item:not(".push")').length;
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');
    const firstItem = $('#list_item .item').first(); // Get first item on the list
    const pageToLoad = parseInt($('#loaded_page').val()) - 1; // Get top page to load but stops if value is 0

    // Opacité modifiée et non display car isInViewport() a besoin de l'élément dans le viewport et pas seulement dans le dom pour fonctionner. La jauge de pagination n'apparaîtra que si tous les items ont été chargés dans la page.
    button.style.opacity = '1';
    //pagerNav.style.opacity = productLoaded < maxItems ? '0' : '1'; 

    if (isInViewport(button)) {
        button.click();
    } else if (isInViewport(firstItem) && pageToLoad > 0) {
        // Check if all top items loaded
        if(productLoaded < maxItems) {
            // If not the case, then load top items
            $('#loaded_page').val(pageToLoad); // Update top page value to latest
            paginationGoToPage(pageToLoad, false, true)
        }
        else if (productLoaded == maxItems) {
            // Stop infinite scroll only when all products loaded
            seeAllBtnClick();
        }
    }
}

// Function to check and restore infinite scroll state
function restoreInfiniteScrollState() {
    // Define pagerNav by selecting the appropriate DOM element
    const pagerNav = document.querySelector('.pager-nav'); // or the correct class/id

    // If pagerNav exists, proceed
    if (pagerNav) {
        const button = pagerNav.querySelector('.button.primary');
        
        // Check if the button exists before adding the event listener
        if (button) {
            button.addEventListener('click', () => {
                const scrollState = sessionStorage.getItem('infiniteScrollState');
                seeAllBtnClick(); // Assuming this is a function defined elsewhere
            });
        }
    }
}

listenForButtonClick();
restoreInfiniteScrollState();
$(function () {

    'use strict';

    /***** HEADER OPACITY ON SCROLL *****/
    var header = document.getElementById("site_head_wrap");

    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {

            if (scrollTop === 0) {

                header.classList.remove("sticky");
            }
        } else {

            if (scrollTop !== 0) {

                header.classList.add("sticky");
            }
        }
    }

    $(window).on("scroll", onWinScroll);

    onWinScroll();

    /***** SWIPERS *****/

    /* Pre header */
    var swiper = new Swiper('#promoSwiper', {
        centeredSlides: true,
        slidesPerView: 'auto',
        autoHeight: true,
        speed: 1800,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
    });

    /* Homepage - image-image module  */
    setTimeout(function(){
        var swiper = new Swiper('.collectionSwiper', {
            direction: 'vertical',
            slidesPerView: 1,
            loopAdditionalSlides: 0,
            // autoplay: {
            //     delay: 3000,
            // },

            navigation: {
                nextEl: '.swiper-collection-button-prev',
                prevEl: '.swiper-collection-button-next',
            },
        });
    }, 1000);

    /* Homepage - slider-module === tetes de gondole  */
    var swiper = new Swiper('#homeGondole', {
        // Screen's width < 1025px
        slidesPerView: 3,
        spaceBetween: 50,

        // Screen's width > 1025px
        breakpoints: {
            1025: {
                slidesPerView: 3,
                spaceBetween: 50,
            }
        },

        navigation: {
            nextEl: '.home-gondole-button-prev',
            prevEl: '.home-gondole-button-next',
        },
    });

    /* Publication - text slider  */
    var swiper = new Swiper('.text-slider', {
        slidesPerView: 3,
        spaceBetween: 50,

        breakpoints: {
            1500: {
                spaceBetween: 110,
            },
        },
    });

    /* Product - association gondole  */
    if ($('#productAssociationSwiper .swiper-slide').length > 5) {
        var swiper = new Swiper('#productAssociationSwiper', {
            // Screen's width < 1025px
            slidesPerView: 5,
            spaceBetween: 5,
            allowTouchMove: false,
            navigation: {
                nextEl: '.product-assoc-button-next',
                prevEl: '.product-assoc-button-prev',
            },
        });
    }

    setTimeout(function() {
        var cart_slider_container = new Swiper('.cart_slider_container', {
            slidesPerView: 5,
            spaceBetween: 5,

            navigation: {
                nextEl: '.product-assoc-button-next',
                prevEl: '.product-assoc-button-prev',
            },
        });
    }, 200);

    /* Product - visited articles gondole  */
    if ($('#productVisitedSwiper .swiper-slide').length > 5) {
            var visitedSwiper = new Swiper('#productVisitedSwiper', {
            // Screen's width < 1025px
            slidesPerView: 5,
            spaceBetween: 5,
            allowTouchMove: false,
            navigation: {
                nextEl: '.product_visited_next',
                prevEl: '.product_visited_prev',
            },
        });
    }



    /* Lookbook - head swiper  */
    var lookbookHeadSwiper = new Swiper('#lookbookHeadSwiper .push_container.swiper-container', {
        slidesPerView: 3,
        spaceBetween: 7,
        roundLengths: true,
        navigation: {
            nextEl: '.lookbook-head-button-next',
            prevEl: '.lookbook-head-button-prev',
        },
        pagination: {
            el: ".lookbook_head_swiper-pagination",
            clickable: true,
        },
        on: {
            init: function(e) {
                //On init retrieve wich slide is matching with current cat and slide to it
                var initial_index;

                //We check the lookbook_cat_list to see which one has been selected
                // We recover all the cats from the lookbook list
                var items = $('.lookbook_cat_list').find('.bloc_itm');
                //We loop them to find wich one is selected
                $.each(items, function(index) {
                    if ($(this).find('.itm_name').hasClass('btn_select')) {
                        //If the current cat in the loop has been selected, recover its position/index
                        initial_index = index;
                    }
                });

                //On init slide to the matching selected index determined with the loop in the HeadSwiper
                this.slideTo(initial_index);
            }
        }
    });

    //set Horizontal menu slider
    setTimeout(function(){
        var $swiperContainer = $('#lookbook_cat_list_swiper');
        var $swiperWrapper = $swiperContainer.find('.swiper-wrapper');
        var totalWidth = 0;

        $swiperContainer.find('.swiper-slide').each(function() {
            totalWidth += $(this).outerWidth(true);
        });

        if (totalWidth > $swiperContainer.outerWidth()) {
            var swiperContainerObject = new Swiper('#lookbook_cat_list_swiper', {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: '.lookbook-head-button-nextt',
                    prevEl: '.lookbook-head-button-prevv',
                },
            });
        } else {
            $('.in_lookbook .nav-container .lookbook-head-button-prevv').hide();
            $('.in_lookbook .nav-container .lookbook-head-button-nextt').hide();
        }

        var currentIndex = $('#lookbook_cat_list_swiper').find('.bloc_itm.currentNav').index();

        if ($('#lookbook_cat_list_swiper .bloc_itm.currentNav').length) {
            swiperContainerObject.slideTo(currentIndex);
        }

        $('#lookbook_cat_list_swiper, .lookbook_cat_list').addClass('autosized');
    }, 300);

    var viewPerTwo = $('#viewPerTwo').hasClass('is-active');
    var viewPerThree = $('#viewPerThree').hasClass('is-active');

    if (viewPerTwo && viewPerThree) {
        $('#viewPerTwo').removeClass('is-active');
    }

    var catsSwiper = new Swiper('.catsSwiper', {
        slidesPerView : 'auto',
        navigation: {
            nextEl: '.cats-button-next',
            prevEl: '.cats-button-prev',
        },
        on: {
            init: function(e) {
                //On init retrieve wich slide is matching with current cat and slide to it
                var initial_index;
                var slides = this.$el.find('.swiper-slide');
                Object.keys(slides).map(function(index, value) {
                    if ($(slides[index]).hasClass('btn_select')) {
                        initial_index = index;
                    }
                });

                this.slideTo(initial_index);
            }
        }
    });

    /* Homepage - cover-module ++ WITH VIMEO */
    if ($('#coverSwiper').length) {

        var id = "coverSwiper";

        // store last played video to put in pause when slide
        var lastPlayedVideo = '';

        var youtubePlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.youtube_video').length >= 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);

            window.onYouTubeIframeAPIReady = function () {
                $('#' + id + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        //add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    coverSwiper.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });

            };
        }

        var coverSwiper = new Swiper('#coverSwiper', {
            slidesPerView: 1,
            // direction: 'vertical',
            autoHeight: true,
            preloadImages: true,
            loop: true, // WP-21612 infinite loop

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },

            navigation: {
                prevEl: '.swiper-cover-button-prev',
                nextEl: '.swiper-cover-button-next',
            }
        });
    }

    //with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if (lastPlayedVideo != '') {
            if ($('#' + lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            } else if ($('#' + lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }



    /* BOX - push slider  */
    var pushSwiper = new Swiper('#pushSlider', {
        slidesPerView: 3.5,

        navigation: {
            nextEl: '.pushs-button-next',
            prevEl: '.pushs-button-prev',
        },
    });

    $('#leftMenu .super_categ_menu .ss_cat_name:not([data-menu="maison-rodier"])').on( 'click touch', function (event) {

        var subMenu = $( this ).next('.objet');
        var targetLocation = $(this).attr('href');

        $('.is-clicked').removeClass('is-clicked');
        $(this).addClass('is-clicked');


        if (subMenu.length) {
            event.preventDefault();

            if (subMenu.is(':visible')) {
                document.location.assign(targetLocation);
            } else {
                $('.objet.is-open').slideUp();
                subMenu.slideDown();
                subMenu.addClass('is-open');
            }
        }
    });

    /**************************************************/
    /* NEW SITE EXISTING USER PWD MESSAGE *************/

    if ($('.newlogin_page #auth').length) {
        var message_translate = Translator.translate('existing_user_pwd_reset');
        var template_holder = "<div class='existing_user_disclaimer'>"+ message_translate +"</div>";
        // setTimeout(function(){
            $('.newlogin_page .login_form_wrapper .title').append(template_holder);
        // }, 1000);
    }
});


/***** RIGHT MENU - to be improved *****//***** RIGHT MENU - to be improved *****/
$(function () {
    function addAccountMenu(e){
        removeAccountMenu();
        e.target.closest(".hasSubMenu").classList.add('hover');
        document.querySelector("#shad_menu").classList.add("actif");
    }
    function removeAccountMenu(){
        if (document.querySelector(".hasSubMenu.hover")) {
            document.querySelector(".hasSubMenu.hover").classList.remove("hover");
        }
        document.querySelector("#shad_menu").classList.remove("actif");
    }

    // action qui ouvre le menu compte
    // $( ".nav_header_item.hasSubMenu" ).mouseover(addAccountMenu);

    let listHasSubMenu = document.querySelectorAll(".nav_header .nav_header_list .hasSubMenu :not(.accountItem)");
    for (let hSMenu of listHasSubMenu){
        hSMenu.addEventListener("touchstart", function(e){
            addAccountMenu(e);
        });
    }
    $(".hasSubMenu.accountItem .item_link").on("touchstart", function(e){
        e.preventDefault();
        addAccountMenu(e);
    });

    // action qui ferme le menu compte
    $( ".nav_header_item.hasSubMenu" ).mouseout(removeAccountMenu);
    document.querySelector("#shad_menu").addEventListener("touchstart", removeAccountMenu);

    // top search
    $('.searchItem .results_list li').on('click', function() {
        $('.searchItem .close_it').click();
    });

    $(".close_it").on('click touch', function (evt) {
        $("#search_home").val("");
        $(".wrap_search_field").fadeOut(300);
        $(".wrap_search_field").removeClass("searching");
        $("body").removeClass("body_fixed");
        $("body").removeClass("search_result");
        window.setTimeout(function () {
            $("#trigger_recherche").show();
        }, 300);
    });
});

$(function() {
    /*****************************************/
    /* PRIMARY NAVBAR *********************/
    if ($('.primary_navbar').length && !$('.nav_bar_frozen').length) {
        $('.navbar_tab').click(function () {
            var tab = $(this).data('tab');
            // Identify navbar to handle multiple primary_navbar occurences
            var navbar = $(this).parent().attr('data-navbar');

            /* change nav active on this elem **/
            $(this).parent().find($('.navbar_tab')).removeClass('active');
            $(this).addClass("active");

            /* If text should be present **/
            if (!$('.no_text').length) {
                $(".navbar_cnt[data-navbar='" + navbar +"']").removeClass('actif');
                $(".navbar_cnt[data-nav='" + tab + "'][data-navbar='" + navbar +"']").addClass('actif');
            }

            /* If nav tabs have specific content to show **/
            if ($('.tab_content').length) {
                $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif');
                $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
            }
        });
    }

    /*****************************************/
    /* CONTENT SIDENAV *********************/

    $('.content_sidenav .sidenav_tab').click(function () {
        /* recover tab on click */
        var tab = $(this).attr('content-tab');

        /* change nav active on this elem */
        $(".sidenav_tab").removeClass("actif");
        $(this).addClass("actif");

        /* change active tab with matching data-tab nav elem */
        $('.content_tab').removeClass('actif');
        $('.content_tab[content-tab="' + tab + '"]').addClass("actif");
    });


    /*****************************************/
    /* TUNNEL *******************************/
    $('#btn_another_relaychronopost_relais').on('click', function(e) {
       setTimeout(function(e) {
           OverlayScrollbars(document.querySelector('#choix_relais_relais .sidebar-right .list-right'),{});
       }, 2000);
    });
});


/***** DISCONNECTION *****/
var elmt;
var loader;
var prev_display;

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // Attention ! Ne pas modifier le délai de 1000ms, pour que l'intelinput ait le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/***** BURGER MENU *****/
$('.burger_menu_container').click(function() {
    $('.burger_menu').toggleClass('is-clicked');
    $('.nav_left').toggleClass('is-open');
})


// INSTAGRAM VIDEO RENDER
$( 'body.homepage .home-module.social-module .home-column li' ).has( 'video' ).addClass( 'insta-video' );

/* Tunnel FAQ dynamic - Keep last section (contact info) open on page load and hide the question */
if ( ( $( 'body.cart' ).length || $( 'body.checkout' ).length ) && $( '.bloc_question' ).length ) {
    $( '.faq_theme_wrapper' ).last().find( '.intitule_question' ).hide();
    $( '.faq_theme_wrapper' ).last().find( '.texte_question' ).show();
}

/***** LEFT MENU ROLLOVER *****/
var isFunctionMenuEnabled = true;
if (isFunctionMenuEnabled) {
    $('.submenu .cat_name a').on('click touch', function(event) {

        //sous categ de submenu
        var subMenu = $(this).parent().next('.ss_menu_categ_wrapper');

        $('.cat_name.is-clicked').removeClass('is-clicked');
        $(this).parent('.cat_name').addClass('is-clicked');

        // Remove current class generated by left_menu.php
        $('.menu_categ_wrapper').find($('.current')).removeClass('current');


        if (subMenu.length) {

            event.preventDefault();
            var locationHref = this.href;

            if (subMenu.is(':visible')) {

                location.href = locationHref;

            } else {
                $('.ss_menu_categ_wrapper.deployed').slideUp();
                subMenu.slideDown().css("display","flex");
                subMenu.addClass('deployed');
            }
        }

    });
}

// Example of disabling the function
// To disable the function, set isFunctionMenuEnabled to false
// You can do this dynamically based on your requirements
// For example, you might disable it when the window width is greater than 1008px
$(window).on("resize", function() {
    if ($(window).width() > 1008) {
        isFunctionMenuEnabled = false;
    } else {
        isFunctionMenuEnabled = true;
    }
});
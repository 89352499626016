// /**
//  * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
//  */
function topCartAppear() {
    var show_top_cart_parent = $('#cart_top');
    var count_bask = $('#cart_top .item_link').attr('data-cart');

    if (count_bask > 3) {
        setTimeout(function() {
            OverlayScrollbars(document.querySelector('#cart_list'), {});
        }, 1000);
    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}

// Function to be executed when the window is resized
function handleResize() {
    var windowWidth = $(window).width();

    /* Move google review_rs for 1024 */
    if (windowWidth < 1025) {
        if ($("#copyright .footer_cats.desktopIpad .review_rs_wrapper").length < 1) {
            $("#copyright .review_rs_wrapper").appendTo("#copyright .footer_cats.desktopIpad");
        }
    } else {
        if ($("#copyright .footer_cats.desktop_large .review_rs_wrapper").length < 1) {
            $("#copyright .review_rs_wrapper").appendTo("#copyright .newsletter_bloc");
        }
    }
}

// Pour lancer le lion bar quand panier plus que 3 ou 2 item
function launchLionBarForCartList() {
    var count_bask = $('#cart_top .cart').attr('data-cart');
    var windowheight = $(window).height();

    if (count_bask > 3) {
        setTimeout(function() {
            OverlayScrollbars(document.querySelector('#cart_list'), {});
        }, 100);
    } else if (count_bask > 2 && windowheight < 901) {
        setTimeout(function() {
            OverlayScrollbars(document.querySelector('#cart_list'), {});
        }, 100);
    }

    $(window).resize(function () {
        if (count_bask > 2 && windowheight < 901) {
            setTimeout(function() {
                OverlayScrollbars(document.querySelector('#cart_list'), {});
            }, 100);
        }
    });
}

$(function() {
    'use strict';

    launchLionBarForCartList();

    $("#cart_top").on('mouseenter touchstart', function (evt) {
        launchLionBarForCartList();
    });

    $("#search_bar").on('click tap touch', function() {
        if (!$("#search_bar_form").hasClass("actif")) {
            $(this).addClass("actif");
            $("#search_bar_form").addClass("actif");
            $(".nav_header").addClass("bgColor");
            $("header#site_head_wrap").addClass("hover");
            $("#shad").addClass("search actif");
            $('#search_home').focus();

        } else {
            $(this).removeClass("actif");
            $("#search_bar_form").removeClass("actif");
            $(".nav_header").removeClass("bgColor");
            $("header#site_head_wrap").removeClass("hover");
            $("#shad").removeClass("search actif");
        }
    });

    $(".close.btn").on('click tap touch', function(){
        $("#search_bar_form").toggleClass("actif");
        $(".nav_header").toggleClass("bgColor");
        $("header#site_head_wrap").toggleClass("hover");

        var checkShad = $("#shad").hasClass("search");
        if (checkShad) {
            $("#shad").removeClass("search actif");
        }
    });

    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if (value.length >= 3){
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }

        $("#search_results").on("click tap touch", function() {
            setTimeout(() => {
                $("#search_bar").click();
                $("#search_bar_form").removeClass("actif");
                $("#shad.search").removeClass("actif");
            }, 400);
        });
    });

    $('.satellite_politique-confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.satellite_politique-confidentialite #cookiesLightbox').addClass('displayCookies');
        if ($('.satellite_politique-confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('hideCookies');
        }
        $('.satellite_politique-confidentialite #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.satellite_politique-confidentialite #js_cookie_refuse, .satellite_politique-confidentialite #js_cookie_accept').click(function() {
        if ($('.satellite_politique-confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.satellite_politique-confidentialite #cookiesLightbox').addClass('hideCookies');
                $('.satellite_politique-confidentialite #shadCookie').removeClass('actif');
            }, 200);
        }
    });

    /* Maquette 1024px refont move avis verifier */
    // Attach the handleResize function to the window resize event
    $(window).resize(handleResize);

    // Initial check on page load
    handleResize();

    $("#copyright .footer_cats.desktopIpad nav").each(function() {
        $(this).on("click tap touch", function() {
            if (!$(this).hasClass("active")) {
                $("#copyright .footer_cats.desktopIpad nav").removeClass("active");
                $("#copyright .footer_cats.desktopIpad nav ul li").hide(200);
                $(this).addClass("active");
                $(this).find("ul li").show(200);

            } else {
                $(this).removeClass("active");
                $(this).find("ul li").hide(200);
            }
        });
    });

    if ($('#wrapper_cart_slider').length) {
        setTimeout(function() {
            $('#wrapper_cart_slider').insertAfter('#main_cart_wrapper');
        }, 200);
    }

    if ($('.step_1 .error_text').length) {
        $('.step_1 .error_text').insertAfter('.bill_line.discount');
    }

    if ($('.step_2 .error_text, .checkout .error_text').length) {
        $('.step_2 .error_text, .checkout .error_text').insertAfter('.bill_line.used_cp');
    }

    $('.step_1 .change_qte').click(function() {
        if ($('.quantity label span:nth-child(2)').length) {
            $('.quantity label span:nth-child(2)').css('display', 'none');
            setTimeout(function() {
                $('.quantity label span:nth-child(2)').css('display', '');
            }, 1000);
        }
    });

    // Updating text for login form in tunnel
    if ($('.in_tunnel #subtitleLogin').length) {
        var newlogin2_txt_tunnel = Translator.translate('newlogin2_txt_tunnel');

        $(".in_tunnel #subtitleLogin").html(newlogin2_txt_tunnel);
        $(".in_tunnel #subtitleLogin").show();
    }

    // Moving payment option titles in trunnel
    if ($('.wrapper_bloc_banque  .bank_title').length) {
        $('.wrapper_bloc_banque  .bank_title').each(function () {
            var title = $(this)
            var paymentOptionBlock = $(this).parents('.wrapper_bloc_banque');

            // Moving title before block
            title.insertBefore(paymentOptionBlock);
        });
    }

    $('.pop_in_confidis').on("click",function(){
        show_lightbox_3xcb();
    });

    $('#cart_top').hover(
        () => $('#shad_menu, #cart_top .item_title').addClass('activate'),
        () => $('#shad_menu, #cart_top .item_title').removeClass('activate')
    );

    $('.nav_header_item.accountItem').hover(
        () => $('#shad_menu, .nav_header_item.accountItem .item_title').addClass('activate'),
        () => $('#shad_menu, .nav_header_item.accountItem .item_title').removeClass('activate')
    );

    $('.nav_header_item.accountItem, #cart_top').hover(
        () => $('#site_head_wrap').addClass('hovered'),
        () => $('#site_head_wrap').removeClass('hovered')
    );

    // Add lookbook specific class to cofidis popin
    if ($('body.category .in_lookbook').length && !$('#popup_cofidis3cb').hasClass('cofidis3cb_lookbook')) {
        $('#popup_cofidis3cb').addClass('cofidis3cb_lookbook');
    }

    if ($('.w-input.w-tel-input .country-list').length) {
        OverlayScrollbars(document.querySelector('.w-input.w-tel-input .country-list'), {});
    }

    setTimeout( () => {
        $('.pagination span, .pagination a').click(function() {
           searchBackToTop()
        });
    }, 500);

    if ($('#fpDescDetails').length) {
        var $container = $('#fpDescDetails');
        var swiperInstance = null;

        function initSwiperIfNeeded() {
            var $slides = $container.find('.swiper-slide');
            var totalWidth = 0;

            $slides.each(function() {
                totalWidth += $(this).outerWidth(true);
            });

            if (totalWidth > $container.width()) {
                if (!swiperInstance) {
                    swiperInstance = new Swiper('#fpDescDetails', {
                        slidesPerView: 'auto',
                        navigation: {
                            nextEl: '.nextNavDesc',
                            prevEl: '.prevNavDesc',
                        },
                        on: {
                            init: ensureCurrentSlideVisible,
                            slideChange: updateCurrentSlide
                        }
                    });
                    attachClickEventToSlides();
                }
            } else {
                if (swiperInstance) {
                    swiperInstance.destroy(true, true);
                    swiperInstance = null;
                }
            }
        }

        function ensureCurrentSlideVisible() {
            var $currentSlide = $container.find('.swiper-slide.current');
            if ($currentSlide.length && swiperInstance) {
                var slideIndex = $currentSlide.index();
                swiperInstance.slideTo(slideIndex, 0, false);
            }
        }

        function updateCurrentSlide() {
            var $currentSlide = $container.find('.swiper-slide.current');
            if ($currentSlide.length) {
                $currentSlide.removeClass('current');
            }
            var activeIndex = swiperInstance.activeIndex;
            $container.find('.swiper-slide').eq(activeIndex).addClass('current');
        }

        function attachClickEventToSlides() {
            $container.on('click', '.swiper-slide', function () {
                $container.find('.swiper-slide.current').removeClass('current');
                $(this).addClass('current');
                if (swiperInstance) {
                    var slideIndex = $(this).index();
                    swiperInstance.slideTo(slideIndex, 500, false);
                }
            });
        }

        initSwiperIfNeeded();

        $(window).on('resize', function() {
            initSwiperIfNeeded();
            ensureCurrentSlideVisible();
        });
    }

    // Added to avoid overriding Vue.js component from _APP (WP-29253)
    if ($('body.cart').length && $('#formPromo').length) {
        $('#formPromo .w-submit-button:not(.w-loader) span').html(Translator.translate('validate')).addClass('show');
    }

    // Show/Hide voir tout btn -> inifinite scroll
    showHideVTBtn();
    removePromoTxt();

    if ($('.footer_cats .triggerCofidisLightbox').length) {
        $('.footer_cats .triggerCofidisLightbox').on('click', function(e) {
            // Prevent any redirection if a link to a page is present
            e.preventDefault();
        });
    }

    moveAlmaBloc();

    setTimeout(() => {
        $(document).ready(function () {
            // Check if the classes _bMClc and _25GrF exist
            if ($('#alma-widget ._bMClc').length && $('#alma-widget ._25GrF').length) {
                // Create a new container div with the class almaAmount
                const newDiv = $('<div class="almaAmount"></div>');
                // Create a new div with the class almaIcon
                const newIconDiv = $('<div class="almaIcon"></div>');

                // Insert the almaAmount container after ._bMClc
                $('#alma-widget ._bMClc').after(newDiv);

                // Move the ._25GrF div into the almaAmount container
                $('#alma-widget ._25GrF').appendTo(newDiv);

                // Move the newIconDiv div into the almaAmount container
                $(newIconDiv).appendTo(newDiv);
            }
        });
    }, 500);
});

// We remove the txt 'promo' from : "1 Twin-set acheté = 1 Twin-set offert avec le code promo TSBOX" as requested by Malaurie/Client -> recettage client as client was to lazy to remove from BO. https://app.clickup.com/t/86c0hbjak
function removePromoTxt() {
    // Iterate over each .offrebox_val inside #list_item
    $('#list_item .offrebox_val').each(function() {
        var offreText = $(this).html();
        if (offreText.indexOf('promo') !== -1 && (offreText.indexOf('Twin-set') !== -1 || offreText.indexOf('twin-set') !== -1)) {
            $(this).html('1 Twin-set acheté = 1 <br>Twin-set <br>offert avec <br>le code TSBOX');
        }
    });
}

function moveAlmaBloc() {
    // move alma block
    if ($('#right_col_sticky #alma-widget').length) {
        $('#alma-widget').insertBefore('#formPanier');
    }
}

function topCartDesappear() {
    var show_top_cart_parent = $('#cart_top');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != '') {
                var force = '';
                if (uri == '/rodier.fr') {
                    force = '?force';
                }
                location.href = data + force;
            }
        }
    });
}

function verif_mail(elementID, fieldname, is_spe) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var mail = new RegExp(
        "^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$",
        "gi"
    );

    path = "";
    if (typeof path_relative_root != "undefined") path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof is_spe == "undefined") {
            var translations = ajax_file(
                path +
                    "ajax_sprintf.php?arg0=%s|%s" +
                    "&arg1=" +
                    fieldname +
                    "&arg2=js_wrong_input_mail"
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                type_champ +
                " :</p>" +
                type_message;
        }
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = "none";
    document.getElementById(id).style.zIndex = "0";
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/*************************************/
/******** affichelostpassword ********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
    //bodyScrollUnlock();
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $("#shad").css("display", "table").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });

        // bodyScrollLock();
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .swiper-slide').mousemove(function (event) {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + "px");
        });

        $('#zoom_box_wrapper .img_large').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper .swiper-slide").length > 1) {
        setTimeout(() => {
            var buyNowSwiper = new Swiper('#buyNowSwiper', {
                slidesPerView: 1,
            });
        }, 100);
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            OverlayScrollbars(document.querySelector("#wrap_itable"), {});
        }, 200);


        setTimeout(() => {
            /* PRIMARY NAVBAR */
            if ($('.primary_navbar').length && !$('.nav_bar_frozen').length) {
                $('.navbar_tab').click(function () {
                    var tab = $(this).data('tab');
                    // Identify navbar to handle multiple primary_navbar occurences
                    var navbar = $(this).parent().attr('data-navbar');

                    /* change nav active on this elem **/
                    $(this).parent().find($('.navbar_tab')).removeClass('active');
                    $(this).addClass("active");

                    /* If text should be present **/
                    if (!$('.no_text').length) {
                        $(".navbar_cnt[data-navbar='" + navbar +"']").removeClass('actif');
                        $(".navbar_cnt[data-nav='" + tab + "'][data-navbar='" + navbar +"']").addClass('actif');
                    }

                    /* If nav tabs have specific content to show **/
                    if ($('.tab_content').length) {
                        $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif');
                        $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
                    }
                });
            }
        }, 100);
    }

    if (id == "selection-offer") {

        setTimeout(function (){
            OverlayScrollbars(document.querySelector('.selection-offer-content'),{});

            var color_list = new Swiper('.color_list', {

                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextColour',
                    prevEl: '.prevColour',
                }
            });

            var size_list = new Swiper('.size_list', {

                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextSize',
                    prevEl: '.prevSize',
                }
            });
        }, 400);
    }

    if (id == "lightbox_achat_express") {
        $("#shad").addClass("lightbox_achat_express");
    }

    if (id === "sendfriendbox") {
        window.setTimeout(function () {
            OverlayScrollbars(document.querySelector('#wrapper_sendfriendform'),{});

        }, 200);
    }

    if (id === "popup_cofidis3cb" && !$( "#" + id ).hasClass("cofidis3cb_lookbook")) {
        $("#shad").addClass("shade_cofidis");
    }
}

function closeMultiShad(id) {
    $("#shad").off("click");

    // Hide modbox and shade and destroy shade's click event
    if (!$( "#" + id ).hasClass("sizeguideBox achat_express") && !$( "#" + id ).hasClass("cofidis3cb_lookbook")) {
        $( "#" + id ).slideUp( "slow" ).queue( function () {
            if ( $( "#shad" ).is( ":visible" ) ) {
                $( "#shad" ).fadeTo( "slow", 0, function () {
                    $( "#shad" ).css( "display", "none" );
                } );
            }

            $(this).dequeue();
        });

    } else {
        $("#content_achat_express #" + id).fadeOut("slow").queue(function() {
            $(this).dequeue();
        });

        if ($( "#" + id ).hasClass("cofidis3cb_lookbook")) {
            $( "#" + id + ".cofidis3cb_lookbook" ).slideUp("slow");
            $("#shad").removeClass("shade_cofidis");
        }
    }

    if (id == "lightbox_achat_express") {
        $("#shad").removeClass("lightbox_achat_express");
    }

    $("#shad.lightbox_achat_express").on("click", function(){
        closeMultiShad('lightbox_achat_express');
    });
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                $(".styled").new_styling_form({
                    type_class_sufix: "",
                    localName_new_elem: "span",
                });
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

// /**
//  * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
//  * @author Timothy Beard / Aurélien Renault
//  * @since  2017-09-27
//  */
function setAvailableSizes() {
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    if (
        (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) ||
        (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false)
    ) {
        data.warehouse_only = 1;
    }

    var elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result) {

                var has_stock = false;
                var sizes_tab = res.result[$('[name="itm_color"]:checked').val()];
                var size_box, is_disabled;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        if (sizes_tab[size_id]) {
                            has_stock = true;
                        }
                    }
                }

                if (has_stock) {
                    $("#bloc_add_basket").addClass('enable_btn');
                } else {
                    $("#bloc_add_basket").addClass('disable_btn');
                }


                var sizes_tab = res.result[produit_id][elem_id];
                var size_box, is_disabled;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        $("#btn_resa_shop").show();
                        $("#bloc_add_alert").hide();
                        size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                            '[value="' + size_id + '"]'
                        );
                        is_disabled = !sizes_tab[size_id];
                        if (is_disabled) {
                            size_box.addClass("disabled");
                        } else {
                            size_box.removeClass("disabled");
                        }
                    } else {
                        $("#btn_resa_shop").hide();
                        $("#bloc_add_alert").show();
                    }
                }


            }
        }
    });
}

// // Show hide div Livraison Gratuit
function cookieHeaderDelivery() {
    var d = new Date();
    d.setTime(d.getTime() + (36500  * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    document.cookie = "cookie_header_delivery=accepted;" + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
    }

$(function() {
    var headerDelivery = $(".header_mention");

    headerDelivery.remove();

    if (getCookie("cookie_header_delivery") != "") {
        $("#site_head_wrap").removeClass("bandeau_on");
        return;
    } else {
        $("#site_head_wrap").prepend(headerDelivery);
        headerDelivery.slideDown(500);
        headerDelivery.addClass("display");
        $("body").addClass("bandeauDelivery");

        $(".close_delais").on("click", function() {
            cookieHeaderDelivery();
            headerDelivery.slideUp(500, function() {
                headerDelivery.remove();
            });
            $("body").removeClass("bandeauDelivery");
            $("#site_head_wrap").removeClass("bandeau_on");
        });
    }

    $('.page_rayon #rayon_sticky_block .bloc_two .form_submit:last').click(function() {
        // Get the current URL
        var currentUrl = window.location.href;

        // Find the position of ".html" in the URL
        var htmlPosition = currentUrl.indexOf('.html');

        // If ".html" exists in the URL, remove everything after it
        if (htmlPosition !== -1) {
            // Create a new URL up to and including ".html"
            var newUrl = currentUrl.substring(0, htmlPosition + 5);

            // Reload the page with the new URL (without tags or parameters)
            window.location.href = newUrl;
        }
    });
});

$(".bloc_lightbox_preco").on("click", function() {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

$("#cart_top.hasSubMenu").hover(function() {
    $('nav.nav_header').toggleClass("hover_panier");
});

function redirectToProduct(url) {
    if (url) {
        window.location.href = url;
    }
}
/** Appel format prodfp < medium pour la vue/3 = meilleure performance */
function switchVisu() {

    var src_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'prodfp':'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'medium':'prodfp';

    $('#list_item .productBigPictures img').each(function () {
        if (typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(src_format,dest_format));
        }
    });
}

/***** RAYON + SEARCH VIEWS CHANGE *****/

function viewChanger(){
    $( '.views .view' ).click(function() {
        if ( ! $(this).hasClass('is-active') ) {
            $('.views .view').removeClass('is-active');
            $(this).addClass('is-active');
            switchVisu();
        }
    })

    $('#viewPerTwo').click(function() {
        if ( $('#list_item').hasClass('view_per_three') ) {
            $('#list_item').removeClass('view_per_three');
        }
    })

    $('#viewPerThree').click(function() {
        if ( ! $('#list_item').hasClass('view_per_three') ) {
            $('#list_item').addClass('view_per_three');
        }
    });
}

/**** ACTION CTA CODE PROMO ***/

$(function () {
    $('#input_codePromo').on("change keyup",function(){
        if ($(this).val().length !=0) {
            $("#clientPromoErr").removeClass('cache');
            $(".form_submit #btn_cmd_valid").addClass('disable');
        }else{
            $("#clientPromoErr").addClass('cache');
            $(".form_submit #btn_cmd_valid").removeClass('disable');
        }
    });

     $('.w-submit-button').on("click",function(){
        $("#clientPromoErr").addClass('cache');
        $(".form_submit #btn_cmd_valid").removeClass('disable');
    });

});
$(function () {
    $( '.js-form-selection-offer').removeClass('hover');
});


$.fn.initAssocBloc = function() {

    var element = $(this);

    function displayItem(element) {
        var list = [];

        $("a", element).each(function(index) {
            list.push($(this));
        });

        list = shuffle(list);

        var length = list.length;
        if (length > 4) {
            length = 4;
        }
        element.html('');
        for (var i = 0 ; i < length ; i++) {
            element.append(list[i]);
        }
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    displayItem(element);

};

$(document).ready(function video_responsive_r() {

    $(window).on('load resize', function () {

        var video_max_height = $('.category #scroll_items .item:not(.push) .imgWrapper').height();
        $('.category #scroll_items .push iframe').css('height', video_max_height);

    }).trigger('load resize');

});


function show_lightbox_3xcb(){
    openMultiShad('popup_cofidis3cb');
}

$(function () {
    /**
     * Page: contact_faq.php
     * Formulaire de contact
     * Append "En savoir plus" in "Je souhaite recevoir les meilleures offres par email et SMS"
     */
    appendElm( $( '#form_contact1' ).find( 'legend.w-fieldset-label' ), $( '#form_contact1' ).find( '.optinLightboxContact' ) );

    $(".submenu_wrapper .menu_categ_wrapper .submenu").each(function () {
        $(this).hover(
            function () {
                $(this).addClass("hovered");
                $(".submenu_wrapper .menu_categ_wrapper .submenu").not(this).addClass("not-hovered");

                if ($(this).find(".cat_name + .ss_menu_categ_wrapper").length > 0) {
                    $("#shad_link").addClass("active_ipad_menu actif");
                }

                if (!$('#search_bar_form').hasClass("actif")) {
                    $("header .nav_header").addClass("bgColor");
                    $("header#site_head_wrap").addClass("hover");
                }
            },
            function () {
                $(this).removeClass("hovered");
                $(".submenu_wrapper .menu_categ_wrapper .submenu").removeClass("not-hovered");

                if ($(this).find(".cat_name + .ss_menu_categ_wrapper").length > 0) {
                    $("#shad_link").removeClass("active_ipad_menu actif");
                }

                if (!$('#search_bar_form').hasClass("actif")) {
                    $("header .nav_header").removeClass("bgColor");
                    $("header#site_head_wrap").removeClass("hover");
                }
            }
        );
    });

    $('#cart_top, .accountItem').hover(
        function() {
            $("header .nav_header").addClass("bgColor");
            $('header#site_head_wrap').addClass('hover');
        },
        function() {
            $("header .nav_header").removeClass("bgColor");
            $('header#site_head_wrap').removeClass('hover');
        }
    );

    // swiper 2 images page adv
    if ($('.home-module.images-2').length) {
        let sliderModuleCount = 1;

        $('.home-module.images-2').each(function() {
            let newSwiperClass = 'swiperimages-2' + sliderModuleCount;
            let newPaginationClass = 'images-2-pagination' + sliderModuleCount;

            $(this).find('.swiperimages-2').addClass(newSwiperClass);
            $(this).find('.images-2-pagination').addClass(newPaginationClass);

            let slideCount = $(this).find('.swiper-slide').length;
            let paginationOptions = {
                el: '.images-2-pagination' + sliderModuleCount,
                type: 'bullets',
                clickable: true,
            };

            if (slideCount <= 2) {
                paginationOptions = false; // Disable pagination if there are 2 or fewer slides
            }

            const swiper_slider_module_three_images = new Swiper('.' + newSwiperClass, {
                slidesPerView: 2,
                spaceBetween: 8,
                pagination: paginationOptions,
            });

            ++sliderModuleCount;
        });
    }

    // swiper 3 images page adv
    if ($('.home-module.images-3').length) {
        let sliderModuleCount = 1;

        $('.home-module.images-3').each(function() {
            let newSwiperClass = 'swiperimages-3' + sliderModuleCount;
            let newPaginationClass = 'images-3-pagination' + sliderModuleCount;

            $(this).find('.swiperimages-3').addClass(newSwiperClass);
            $(this).find('.images-3-pagination').addClass(newPaginationClass);

            let slideCount = $(this).find('.swiper-slide').length;
            let paginationOptions = {
                el: '.images-3-pagination' + sliderModuleCount,
                type: 'bullets',
                clickable: true,
            };

            if (slideCount <= 3) {
                paginationOptions = false; // Disable pagination if there are 3 or fewer slides
            }

            const swiper_slider_module_three_images = new Swiper('.' + newSwiperClass, {
                slidesPerView: 3,
                spaceBetween: 8,
                pagination: paginationOptions,
            });

            ++sliderModuleCount;
        });
    }

    /* Initialise le swiper pour le swiper 2 images: slider-module et prend en compte plusieurs utilisations du même module */
    setTimeout(() => {
        if ($('.tg-module.tg_2').length ) {
            let sliderModuleCount = 1;

            $('.tg-module.tg_2').each( function () {
                let newSwiperClass = 'swiperTg_2' + sliderModuleCount;
                let newPaginationClass = 'tg-2-module-pagination' + sliderModuleCount;

                $(this).find('.swiperTg_2').addClass(newSwiperClass);
                $(this).find('.tg-2-module-pagination').addClass(newPaginationClass);

                if ($(this).find('.swiper-slide').length > 1) {
                    let mySwiper = '.swiperTg_2' + sliderModuleCount;
                    let myPagination = '.tg-2-module-pagination' + sliderModuleCount;
                    let slideCount = $(this).find('.swiper-slide').length;
                    let paginationOptions = {
                        el: '.tg-2-module-pagination' + sliderModuleCount,
                        type: 'bullets',
                        clickable: true,
                    };

                    if (slideCount <= 2) {
                        paginationOptions = false; // Disable pagination if there are 2 or fewer slides
                    }

                    const swiper_slider_module = new Swiper(mySwiper, {
                        slidesPerView: 2,
                        spaceBetween: 7,
                        pagination: paginationOptions,
                    });
                }

                ++sliderModuleCount;
            });
        }
    }, 500);

    /* Initialise le swiper pour le swiper 3 images: slider-module et prend en compte plusieurs utilisations du même module */
    setTimeout(() => {
        if ($('.tg-module.tg_3').length ) {
            let sliderModuleCount = 1;

            $('.tg-module.tg_3').each( function () {
                let newSwiperClass = 'swiperTg_3' + sliderModuleCount;
                let newPaginationClass = 'tg-3-module-pagination' + sliderModuleCount;

                $(this).find('.swiperTg_3').addClass(newSwiperClass);
                $(this).find('.tg-3-module-pagination').addClass(newPaginationClass);

                if ($(this).find('.swiper-slide').length > 1) {
                    let mySwiper = '.swiperTg_3' + sliderModuleCount;
                    let myPagination = '.tg-3-module-pagination' + sliderModuleCount;
                    let slideCount = $(this).find('.swiper-slide').length;
                    let paginationOptions = {
                        el: myPagination,
                            type: 'bullets',
                            clickable: true,
                    };

                    if (slideCount <= 3) {
                        paginationOptions = false; // Disable pagination if there are 3 or fewer slides
                    }

                    const swiper_slider_module = new Swiper(mySwiper, {
                        slidesPerView: 3,
                        spaceBetween: 8,
                        pagination: paginationOptions,
                    });
                }

                ++sliderModuleCount;
            });
        }
    }, 500);

    $("#shad_menu, #shad").on('click', function () {

        /* Rayon shad Close*/
        if ($("#shad_menu").hasClass("actif")) {
            $("#shad_menu").toggleClass("actif");
            if ($("#rayon_sticky_block").hasClass("actif")) {
                $("#rayon_sticky_block").removeClass("actif");
            }
        }

        /* Top search shad Close*/
        if ($("#shad.search").hasClass("actif")) {
            $("#shad.search").toggleClass("actif");
            $("#search_bar").click();
        }

        if ($("body").hasClass("fixed")) {
            $("body").removeClass("fixed");
        }

        if ($("#rayon_sticky_block").length) {
            $("#rayon_sticky_block section.filtre").css({
                "overflow-y": "hidden",
                "right": "-100%",
                "width": "440px"
            });
        }
    });


    if ($('body.customer').length && $('.right-container.wrapper_content_stock_alert').length) {
        // Add class customer_stock_alert to body when alert stock is available
        $('body.customer').addClass('customer_stock_alert');

        // Override _app to remove semi-colon from taille
        $('.sub_taille').each(function() {
            $(this).text($(this).text().replace(':', ''));
        });
    }

    /* Menu auto add width to make bold animation not increase spaces between each menu */
    if ($(window).width() > 1024) {
        $("#leftMenu").addClass("greaterThan1024");

    } else {
        $("#leftMenu").removeClass("greaterThan1024");
        $("#leftMenu").addClass("lessThan1025");
    }

    $(window).on("resize", function(){
        if ($(window).width() > 1024) {
            $("#leftMenu").addClass("greaterThan1024");

        } else {
            $("#leftMenu").removeClass("greaterThan1024");
            $("#leftMenu").addClass("lessThan1025");
        }
    });

    setTimeout(() => {
        var getNoOfMenu = $("#leftMenu .menu_categ_wrapper div.submenu").length;
        if (getNoOfMenu > 5) {
            $("#leftMenu .menu_categ_wrapper").addClass("moreThanFive");
        }

        if (getNoOfMenu > 7) {
            $("#leftMenu .menu_categ_wrapper").addClass("moreThanSeven");
        }

        /* Category SEO txt read more */
        if ($('body').hasClass('product_page')) {
            $(document).ready(function() {
                $('.product_description .txt').each(function() {
                    var fullTextElement = $(this);
                    var fullText = fullTextElement.html().trim();
                    var maxLength = 90;
                    var windowWidth = $(window).width();

                    if (windowWidth <= 1024) {
                        var maxLength = 45;
                    } else if (windowWidth <= 1440) {
                        var maxLength = 65;
                    }

                    $(this).css('opacity', '1');

                    function truncateHtml(text, maxLength) {
                        var trimmedText = "";
                        var currentLength = 0;
                        var regex = /(<\/?[^>]+>|[^<]+)/g;
                        var match;
                        while ((match = regex.exec(text)) !== null) {
                            if (match[0].startsWith("<")) {
                                trimmedText += match[0];
                            } else {
                                if (currentLength + match[0].length > maxLength) {
                                    trimmedText += match[0].slice(0, maxLength - currentLength);
                                    trimmedText += "...";
                                    break;
                                }
                                trimmedText += match[0];
                                currentLength += match[0].length;
                            }
                        }
                        return trimmedText;
                    }

                    if (fullText.length > maxLength) {
                        var trimmedText = truncateHtml(fullText, maxLength);
                        fullTextElement.html(trimmedText + ' <span class="seeMore">' + Translator.translate('see_more') + '</span>');
                        fullTextElement.addClass('trimmed-text');

                        var fullTextContainer = $('<div class="full-text" style="display: none;">' + fullText + ' <span class="seeLess">' + Translator.translate('see_less') + '</span></div>');
                        fullTextElement.after(fullTextContainer);

                        fullTextElement.find('.seeMore').on('click', function() {
                            fullTextElement.fadeOut(300, function() {
                                fullTextContainer.fadeIn(300);
                            });
                        });

                        fullTextContainer.find('.seeLess').on('click', function() {
                            fullTextContainer.fadeOut(300, function() {
                                fullTextElement.fadeIn(300);
                            });
                        });
                    } else {
                        fullTextElement.html(fullText);
                        fullTextElement.removeClass('trimmed-text');
                    }
                });
            });
        }

        /* SEO rayon */
        if ($("#push_rayon .txtWrapper").length) {
            $(document).ready(function () {
                var fullTextElement = $('.txt.full-text');
                var fullText = fullTextElement.html().trim();
                var maxLength = 600;

                $('#push_rayon .txtWrapper').css('opacity', '1');

                function truncateHtml(text, maxLength) {
                    var trimmedText = "";
                    var currentLength = 0;
                    var regex = /(<\/?[^>]+>|[^<]+)/g;

                    var match;
                    while ((match = regex.exec(text)) !== null) {
                        if (match[0].startsWith("<")) {
                            trimmedText += match[0];
                        } else {
                            if (currentLength + match[0].length > maxLength) {
                                trimmedText += match[0].slice(0, maxLength - currentLength);
                                trimmedText += "[...]";
                                break;
                            }
                            trimmedText += match[0];
                            currentLength += match[0].length;
                        }
                    }
                    return trimmedText;
                }

                if (fullText.length > maxLength) {
                    var trimmedText = truncateHtml(fullText, maxLength);
                    fullTextElement.html(trimmedText);
                    $('.seeMore').show();

                    $('.seeMore').on('click', function () {
                        fullTextElement.html(fullText);
                        $('.seeMore').hide();
                        $('.seeLess').show();
                    });

                    $('.seeLess').on('click', function () {
                        fullTextElement.html(trimmedText);
                        $('.seeMore').show();
                        $('.seeLess').hide();
                    });
                }
            });
        }
    }, 350);

    if ($('body.customer.body_login').length && $(".newlogin_page.vp").length) {
        //  Remove "/ Invité" from sub-title only for Ventes privilege login
        let title = $('.login_form_wrapper .title h3').text();
        title = title.replace(" / Invité", "");
        title = title.replace(" / Guest", "");
        $('.login_form_wrapper .title h3').text(title);

        // Breadcrumb -> change current to Vente privilege
        $('#breadcrumbs .breadcrumbs .breadcrumb.current p').html(Translator.translate('my_account_vp'));
    }

    // Check if "Edits" pages and add class "page-edits" to body
    if ($('.page_rayon.rayon_lookbook').length) {
        let currentUrl = window.location.href;

        if (currentUrl.indexOf("edits") !== -1) {
            $('body').addClass('page-edits');

            $('#breadcrumbs .breadcrumb a').each(function() {
                if ($(this).text().trim().toLowerCase() === 'edits') {
                    $(this).text($(this).text().replace('E', 'É'));
                }
            });
        }

        setTimeout(() => {
            $('.page_rayon.rayon_lookbook').css('opacity', '1');
        }, 500);
    }

    if ($('body.product_page').length) {
        // For FP -> Add a line over "#gondole_derniers_articles_vus" when ".product_assoc_container" is not present
        if ($('#gondole_derniers_articles_vus').length && $('.product_assoc_container').length === 0) {
            $('#gondole_derniers_articles_vus').css({
                'margin-top': '60px'
            });
        }
    }

    if ($('body.contact_faq').length) {
        // Style the textarea's scrollbar
        setTimeout(() => {
            var textarea = $("#form_contact1 .w-textarea textarea");
            textarea.height(188);
            OverlayScrollbars(textarea[0], {});
        }, 800);
    }

    $("#rayon_sticky_block .close_pop.js-close-filter").on("click", function() {
        filterTrigger();
    });

    /* Page rayon Filter swiper */
    swiperFilter();

    /* Get the number of produts in the hover panier */
    var getPanierHoverNoProduit = $("#show_top_cart #cart_list .product-link").length;

    if (getPanierHoverNoProduit > 2) {
        $("#show_top_cart #cart_list").addClass("more_than_two_products");
    }

    if (getPanierHoverNoProduit > 3) {
        $("#show_top_cart #cart_list").addClass("more_than_three_products");
    }

    setTimeout(() => {
        const swipers = document.querySelectorAll('.productSwiper');
        swipers.forEach((container, index) => {
            if ($(container).find('.productSwiper').length == 0 && $(container).find('.swiper-wrapper').length && $(container).find('.swiper-slide').length > 1) {
                productSwiper = new Swiper(container, {
                    slidesPerView: 1,
                });
            }
        });
    }, 500);

    if ($('#breadcrumbs').length ) {
        $('#breadcrumbs').css({'opacity': '1'});
    }

    /* Filters Rayon */
    lionBarOnFilter();
});

$(document).ready(function() {
    // Select the second .home-column .home-module-cta a
    var $secondChild = $('.image-image-image .home-column').eq(1).find('.home-module-cta a');

    // Select the third .home-column .home-module-cta .slide_video
    var $thirdChild = $('.image-image-image .home-column').eq(2).find('.home-module-cta .slide_video');

    // Check if both elements are present
    if ($secondChild.length && $thirdChild.length) {
        // Create a temporary holder for one of the elements
        var $temp = $('<div>').hide(); // Create a hidden div for temporary storage

        // Swap the elements
        $secondChild.before($temp);       // Insert temporary div before the second element
        $thirdChild.after($secondChild);  // Move second element after the third element
        $temp.replaceWith($thirdChild);   // Replace temporary div with the third element
    }

    /* Add a video to the Tunnel-Bank thank you page  */
    $(document).ready(function () {
        setTimeout(() => {
            if ($("body").hasClass("bankThanks")) {
                const videoPath = 'img/video_remerciements.mp4';
                const videoURL = path_relative_root + videoPath;
                const bankThanksTop = document.querySelector('.bankThanksTop');
                const videoRemerciements = Object.assign(document.createElement('div'), { id: 'videoRemerciements' });
                const videoElement = document.createElement('video');

                videoElement.setAttribute('src', videoURL);
                videoElement.removeAttribute('controls');
                videoElement.setAttribute('autoplay', 'autoplay');
                videoElement.setAttribute('loop', 'true');
                videoElement.setAttribute('muted', '');
                videoElement.setAttribute('playsinline', 'playsinline');
                videoElement.style.width = '100%';

                videoRemerciements.appendChild(videoElement);
                bankThanksTop.appendChild(videoRemerciements);

                videoElement.muted = true;
            }
        }, 500);
    });
});

$("body").on("click", ".form_itm.check.size", function() {
    let form = $(this).closest('form');
    if ($(this).hasClass('disabled') || $(this).find('.disabled').length) {
        form.find('.bloc_add_color').addClass('hideAchat');
    } else if (!$(this).hasClass('disabled') || !$(this).find('.disabled').length) {
        form.find('.bloc_add_color').removeClass('hideAchat');
    }
});

$("body").on("click", ".form_itm.check.size", function() {
        var $cartSliderWrapper = $(this).closest('#wrapper_cart_slider .productVisualMulti .product_rollover');
    if ($(this).hasClass('disabled') || $(this).find('.disabled').length) {
        $cartSliderWrapper.find('.bloc_add_color').addClass('hideAchat');
        $cartSliderWrapper.find('.bloc_add_color').removeClass('showAchat');
    } else {
        $cartSliderWrapper.find('.bloc_add_color').addClass('showAchat');
        $cartSliderWrapper.find('.bloc_add_color').removeClass('hideAchat');
    }
});

/* Page rayon Filter swiper */
function swiperFilter() {
    if ($('#slideCateg').length) {
        var totalWidth = 0;
        var $slideCateg = $('#slideCateg');
        var containerWidth = $slideCateg.outerWidth();

        setTimeout(function() {
            $slideCateg.find('.swiper-slide').each(function () {
                var slideWidth = $(this).outerWidth();
                let slideMarginRight = parseInt($(this).css('margin-right'), 10);

                totalWidth += slideWidth;
                totalWidth += slideMarginRight; // Account's for margin-right on each slides
            });

            var slideCount = $slideCateg.find('.swiper-slide').length;
            var centered = slideCount <= 5;

            if (totalWidth > containerWidth && slideCount > 2) {
                $slideCateg.removeClass('non-active');

                var slideCateg = new Swiper('#slideCateg', {
                    slidesPerView: 'auto',             
                    centeredSlides: centered,
                    centeredSlidesBounds: centered,
                    initialSlide: 0,
                    watchOverflow: true,
                    navigation: {
                        nextEl: '.swiper-button-next.slideCateg',
                        prevEl: '.swiper-button-prev.slideCateg',
                    },
                });
            }

            $(window).on('load resize', function () {
                slideCateg.update();
            });
        }, 1000);
    }

    $('.form_itm.check.color').click(function() {
        $('#filter_color .form_itm label span').addClass('adjustSize');
    });

    $(".page_rayon .filter_wrapper").css({"opacity": "1"});
}

function triggerFunctionOnSearchEnd() {
    swiperFilter();

    // Rajouter pour que isCategSuper rest 0 pour la recherche.
    var searchIsCategSuper = $('.dynasearchwrapper .productSwiper.productVisualMulti form input[name="isCategSuper"]');
    if (searchIsCategSuper.val() != 0) {
        searchIsCategSuper.val("0");
    }
}

function appendElm($parentEl, $childEl) {
    if ($parentEl.length && $childEl.length) {
        return $parentEl.append($childEl);
    }
}

function openMenuRight() {
    $("header .menu_top_wrapper").toggleClass("active_ipad");
    $("#shad_menu").toggleClass("active_ipad_menu actif");
    $(".menu_burger").toggleClass("actif");
}

function filterTrigger() {
    $("#trigger_filtre").toggleClass("actif");
    $("#rayon_sticky_block").toggleClass("actif");
    $("#shad_menu").toggleClass("actif filter");
    $("body").toggleClass("fixed");

    lionBarOnFilter();
    if ($("#rayon_sticky_block").length) {
        $("#rayon_sticky_block section.filtre").css({
            "overflow-y": "hidden",
            "right": "-100%",
            "width": "440px"
        });
    }
}

function correctionPosition() {
    if ($("#rayon_sticky_block.filters.actif").length) {

        var getWindowWidth = $(window).width();
        if (getWindowWidth < 1900) {
            $("#rayon_sticky_block section.filtre").css({
                "overflow-y": "scroll",
                "right": "-18px",
                "width": "458px"
            });
        } else {
            $("#rayon_sticky_block section.filtre").css({
                "overflow-y": "hidden",
                "right": "0",
                "width": "440px"
            });
        }
    }

    if ($('#bloc_wrapper').length) {
        setTimeout(function() {
            OverlayScrollbars(document.querySelector('#bloc_wrapper'), {});
        }, 800);
    }
}

correctionPosition();

function lionBarOnFilter() {
    setTimeout(() => {
        // Initialize lionbars on page load
        correctionPosition();

        // Reinitialize lionbars on window resize + align correct position
        $(window).on('resize', function() {
            correctionPosition();
        });
    }, 15);
}

// Reinitialize lionbars on window resize + align correct position
$(window).on('resize', function() {
    correctionPosition();
});

if ($('body').hasClass('homepage')) {
    setInterval(() => {
        if ($('.bandeau_on.scrolled.sticky').length) {
            $('body.homepage #site_global_wrap').css('margin-top', '0');
        } else {
            $('body.homepage #site_global_wrap').css('margin-top', '-90px');
        }
    }, 100);
}

// Reload login block
function reloadBlocLogin(evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    // Active la fonction reloadBlocLoginSubF si elle est presente
    if (typeof reloadBlocLoginSubF === "function") {
        reloadBlocLoginSubF();
    }

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');
        subLogin.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

setTimeout(() => {
    $('#btt_button').on('click', function() {
        // Smooth scroll to the top
        $('html, body').animate({ scrollTop: 0 }, 'slow'); // Adjust speed ('slow', 'fast', or a custom value in ms)
    });
}, 500);

function searchBackToTop() {
    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $('html, body').animate({ scrollTop : 0 }, (speed * 2));
}

// Verify phone number
function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    if ($('#sendfriend_form_to .w-textarea .w-input-element').length) {
        OverlayScrollbars(document.querySelector('#sendfriend_form_to .w-textarea .w-input-element'), {});
    }

    OverlayScrollbars(document.querySelector('#popup_cofidis3cb .popup_container'), {});

    // Fonction chargée de simuler le clic et de masquer le bouton au retour d'une fiche produit SI le cookie 'p_id' est présent ET si la valeur d'initial_page est supérieure à 1.
    if (getCookie('p_id') && $('#initial_page').val() > 1) {
        seeAllBtnClick();
    }

    if (navigator.userAgentData) {
        if (navigator.userAgentData.platform === 'macOS') {
            document.body.classList.add('mac');
        }
    } else if (navigator.platform.toLowerCase().indexOf('mac') >= 0 || navigator.userAgent.toLowerCase().indexOf('mac') >= 0) {
        document.body.classList.add('mac');
    }


});

if ($(".thanksRecap").length) {
    $(".bankThanksTop h1").appendTo(".thanksRecap");
}

// Show/Hide voir tout if more that 25 product as its on inifinite scroll
function showHideVTBtn() {
    let productLoaded = $('#list_item .item:not(".push")').length;
    let nb_prod_default = $('#nb_prod_default').val();
    if (productLoaded > nb_prod_default) {
        $('.pagerNav.end_pagi .loader_scroll').fadeTo(100, 0);
    } else {
        $('.pagerNav.end_pagi .loader_scroll').fadeTo(100, 1);
    }
}



// Apply overlayscrollbars to the website not on page load but directly in script
const osInstance = OverlayScrollbars(document.body, {});


function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}